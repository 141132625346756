
function timeDifference3(current, previous) {
  const milliSecondsPerMinute = 60 * 1000
  const milliSecondsPerHour = milliSecondsPerMinute * 60
  const milliSecondsPerDay = milliSecondsPerHour * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 7
  const elapsed = current - previous
  if (elapsed < milliSecondsPerDay) {
    return 'Today'
  } else if (elapsed < milliSecondsPerMonth) {
    return new Date(previous).toLocaleString('en-GB', { weekday: 'long' })
  } else {
    return new Date(previous).toLocaleString("en-GB",{ year: 'numeric', month: 'numeric', day: 'numeric' })
  }
}

function timeDifference2(current, previous) {
  const milliSecondsPerMinute = 60 * 1000
  const milliSecondsPerHour = milliSecondsPerMinute * 60
  const milliSecondsPerDay = milliSecondsPerHour * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 30
  const milliSecondsPerYear = milliSecondsPerDay * 365

  const elapsed = current - previous

  if (elapsed < milliSecondsPerMinute / 3) {
    return 'just now'
  }

  if (elapsed < milliSecondsPerMinute) {
    return 'less than 1 min ago'
  } else if (elapsed < milliSecondsPerHour) {
    return Math.round(elapsed / milliSecondsPerMinute) + ' minutes ago'
  } else if (elapsed < milliSecondsPerDay) {
    return Math.round(elapsed / milliSecondsPerHour) + ' hours ago'
  } else if (elapsed < milliSecondsPerMonth) {
    return Math.round(elapsed / milliSecondsPerDay) + ' days ago'
  } else if (elapsed < milliSecondsPerYear) {
    return Math.round(elapsed / milliSecondsPerMonth) + ' months ago'
  } else {
    return Math.round(elapsed / milliSecondsPerYear) + ' years ago'
  }
}
function timeDifference(current, previous) {
  const milliSecondsPerMinute = 60 * 1000
  const milliSecondsPerHour = milliSecondsPerMinute * 60
  const milliSecondsPerDay = milliSecondsPerHour * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 30
  const milliSecondsPerYear = milliSecondsPerDay * 365

  const elapsed = current - previous

  if (elapsed < milliSecondsPerMinute / 3) {
    return 'just now'
  }

  if (elapsed < milliSecondsPerMinute) {
    return 'less than 1 min ago'
  } else if (elapsed < milliSecondsPerHour) {
    return Math.round(elapsed / milliSecondsPerMinute) + ' min ago'
  } else if (elapsed < milliSecondsPerDay) {
    return Math.round(elapsed / milliSecondsPerHour) + ' h ago'
  } else if (elapsed < milliSecondsPerMonth) {
    return Math.round(elapsed / milliSecondsPerDay) + ' days ago'
  } else if (elapsed < milliSecondsPerYear) {
    return Math.round(elapsed / milliSecondsPerMonth) + ' mo ago'
  } else {
    return Math.round(elapsed / milliSecondsPerYear) + ' years ago'
  }
}
function timeDifferenceDays(current, previous) {
  const milliSecondsPerDay = 60 * 1000 * 60 * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 30
  const milliSecondsPerYear = milliSecondsPerDay * 365

  const elapsed = current - previous

  if (elapsed < milliSecondsPerDay) {
    return 'today'
  } else if (elapsed < milliSecondsPerMonth) {
    return Math.round(elapsed / milliSecondsPerDay) + ' days ago'
  } else if (elapsed < milliSecondsPerYear) {
    return Math.round(elapsed / milliSecondsPerMonth) + ' mo ago'
  } else {
    return Math.round(elapsed / milliSecondsPerYear) + ' years ago'
  }
}
/**
 * Display dates in a suitable way.
 *
 * @param date
 */
 export const timeDiff = (date) => {
   const now = new Date().getTime()
   const updated = new Date(date).getTime()
   return timeDifference(now, updated)
 }
 export const timeDiff3 = (date) => {
   const now = new Date().getTime()
   const updated = new Date(date).getTime()
   return timeDifference3(now, updated)
 }

export const timeDiff2 = (date) => {
 const now = new Date().getTime()
 const updated = new Date(date).getTime()
 return timeDifference2(now, updated)
}
export const timeDiffDays = (date) => {
  const now = new Date().getTime()
  const updated = new Date(date).getTime()
  return timeDifferenceDays(now, updated)
}

export function retrieveImageFromClipboardAsBlob(pasteEvent, callback){
  if(pasteEvent.clipboardData === false){
    if(typeof(callback) == "function"){
      callback(undefined);
    }
  };
  var items = pasteEvent.clipboardData.items;
  if(items === undefined){
    if(typeof(callback) == "function"){
      callback(undefined);
    }
  };
  for (var i = 0; i < items.length; i++) {
    if (items[i].type.indexOf("image") === -1) continue;
    var blob = items[i].getAsFile();
    if(typeof(callback) == "function"){
      callback(blob);
    }
  }
}

export function newLive(picture, title, body, onclick, logo) {
  const livenoti = document.getElementById("livenoti")
  const notification = document.createElement('div');
  notification.className = 'notification'
  notification.onclick = () => onclick && onclick()
  const header = document.createElement('div');
  header.className = 'header'
  let pic
  if(logo && logo.logo){
    pic = document.createElement('img');
    pic.src = logo.logo
  }else if(logo && logo.letter){
    pic = document.createElement('div');
    pic.className = 'logop'
    pic.style = `background: ${logo.color};`
    pic.appendChild(document.createTextNode(logo.letter))
  }else{
    pic = document.createElement('img');
    pic.src = picture
  }
/*
if(logo && logo.logo){
  pic = document.createElement('img');
  pic.src = picture
}else if(logo && logo.color){
  pic = document.createElement('div');
  pic.className = 'logop'
  pic.style = `background: ${logo.color};`
  pic.appendChild(document.createTextNode(logo.letter))
}

*/
  const text = document.createElement('div');
  text.className = 'text'

  const strong = document.createElement('strong');
  strong.className = 'strong'
  strong.appendChild(document.createTextNode(title));

  text.appendChild(strong);
  text.appendChild(document.createTextNode(body));

  notification.appendChild(pic);
  notification.appendChild(text);
/*
  const body = document.createElement('div');
  body.className = 'body'
  body.appendChild(document.createTextNode(message));
*/
  //notification.appendChild(header);
//  notification.appendChild(body);
/*
  const close = document.createElement('img');
  close.className = 'close'
  close.src =  '/images/landing/Close2.svg'
  close.onmousedown = () => {
    livenoti.removeChild(notification)
    clearTimeout(time)
  }
  notification.appendChild(close);
*/
  livenoti.appendChild(notification);
  setTimeout(() => notification && livenoti.removeChild(notification), 7000)
}

export function addError(message) {
  const errors = document.getElementById("errors")
  const div = document.createElement('div');
  const text = document.createTextNode(message);
  const img = document.createElement('img');
  img.src =  '/images/landing/Close2.svg'
  img.onmousedown = () => {
    errors.removeChild(error)
    clearTimeout(time)
  }
  const error = document.createElement("div")
  div.appendChild(text);
  error.appendChild(div);
  error.appendChild(img);
  errors.appendChild(error);
  const time = setTimeout(() => error && errors.removeChild(error), 3000)
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const cropBase64 = (base64, crop) => new Promise((resolve, reject) => {
  const size = 150
  var canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.onload = function() {
    ctx.drawImage(img, crop.x, crop.y, crop.width, crop.height, 0, 0, size, size);
    resolve(canvas.toDataURL())
  }
  img.onerror = error => reject(error);
  img.setAttribute('hidden', true); // works for me
  img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
  img.src = base64;
})
export const base64fromURL2 = (image) => new Promise((resolve, reject) => {
  var size = 100
  var canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  var ctx = canvas.getContext("2d")
  var img = new Image();
  img.onload = function() {
    ctx.drawImage(img, 0, 0, size, size)
    resolve(canvas.toDataURL())
  }
  img.setAttribute('hidden', true); // works for me
  img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
  img.src = image;
})

export const base64fromURL = (image) => new Promise((resolve, reject) => {
  var size = 100
  var canvas = document.createElement("canvas");
  canvas.width = size*2;
  canvas.height = size;
  var ctx = canvas.getContext("2d")
  var img = new Image();
  img.onload = function() {
    if(img.width < img.height*2){
      const width = size*2
      const height = img.height/img.width*width
      const top = (size - height)/2
      ctx.drawImage(img, 0, top, width, height)
      resolve(canvas.toDataURL())
    }else{
      const height = size
      const width = img.width/img.height*height
      const left = (size*2 - width)/2
      ctx.drawImage(img, left, 0, width, height)
      resolve(canvas.toDataURL())
    }
  }
  img.setAttribute('hidden', true); // works for me
  img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
  img.src = image;
})
/*
export const resizeLogo = base64 => new Promise((resolve, reject) => {
    const size = 100
    var canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.onload = function() {
      ctx.drawImage(img, 0, 0, size, size)
      resolve(canvas.toDataURL())
    }
    img.onerror = error => reject(error);
    img.setAttribute('hidden', true); // works for me
    img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
    img.src = base64;
})
*/
/*
export const resizeSquare = base64 => new Promise((resolve, reject) => {
  const size = 150
  var canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.onload = function() {
    if(img.width < img.height){
      const width = size
      const height = img.height/img.width*width
      const top = (size - height)/2
      ctx.drawImage(img, 0, top, width, height)
      resolve(canvas.toDataURL())
    }else{
      const height = size
      const width = img.width/img.height*height
      const left = (size - width)/2
      ctx.drawImage(img, left, 0, width, height)
      resolve(canvas.toDataURL())
    }
  }
  img.onerror = error => reject(error);
  img.setAttribute('hidden', true); // works for me
  img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
  img.src = base64;
})
*/
export const formatImage = (image) => new Promise(async (resolve, reject) => {
  const size = 350
  var canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.onload = function() {
    if(img.width < img.height){
      const width = size
      const height = img.height/img.width*width
      const top = (size - height)/2
      ctx.drawImage(img, 0, top, width, height)
      //resolve(canvas.toDataURL())
      canvas.toBlob(function(blob) {
        resolve(blob)
      })
    }else{
      const height = size
      const width = img.width/img.height*height
      const left = (size - width)/2
      ctx.drawImage(img, left, 0, width, height)
      //resolve(canvas.toDataURL())
      canvas.toBlob(function(blob) {
        resolve(blob)
      })
    }
  }
  img.onerror = error => console.log(error);
  img.setAttribute('hidden', true); // works for me
  img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
  img.src = await toBase64(image);
})
/*
function _arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}
*/
const setVideoTime = (video, iterations) => {
  if (!isNaN(video.duration)) {
    video.currentTime = (video.duratione ? video.duratione : video.duration) * iterations / 6;
  }
}
export const ObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

export const uniqify = (array, key) => array.reduce((prev, curr) => prev.find(a => a[key] === curr[key]) ? prev : prev.push(curr) && prev, [])
export const typeFile = (type) => type.includes('image') ? 'image' : type.includes('pdf') ? 'Pdf' : type.includes('word') ? 'Word' : type.includes('xml') ? 'Xls' : 'unknown'
export const srcType = (type) => !type ? '/images/files/Unknown.svg' : type.includes('pdf') ? '/images/files/PdfBorder.png' : type.includes('word') ? '/images/files/WordBorder.png' : type.includes('xml') ? '/images/files/XlsBorder.png' : '/images/files/Unknown.svg'
export const typeNoti = (type) => type.includes('invite') ?'/images/basiced/Rocket.svg' : type.startsWith('Project') ? '/images/basiced/TaskYellow.svg' : type.startsWith('Task') ? '/images/basiced/TaskGreen.svg' : type.startsWith('Story') ? '/images/basiced/Stories.svg' : type.includes('media') ?'/images/basiced/Media.svg':type.includes('document') ?'/images/basiced/Folder.svg':'/images/basiced/TaskGreen.svg'
export const symbolCurrency = (currency) => currency === 'GBP' ? '£' : currency === 'USD' ? '$' : currency === 'EUR' && '€'
export const sortchat = (a, b) => new Date(b.updated) - new Date(a.updated)
export const sortchat2 = (a, b) => new Date(b.lastMessage) - new Date(a.lastMessage)

//export const sortchat2 = (a, b) => new Date(b.messages.length > 0 ? b.messages[0].createdAt : b.createdAt) - new Date(a.messages.length > 0 ? a.messages[0].createdAt : a.createdAt)

export const sortmessages = (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const sortstories = sortmessages

export const capitalizeFirstLetter = (string) => { return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() }

export const maxLength = (tasks) => {
  let total = 0
  for(let [index, task] of tasks.entries()){
    total = task.logo ? total+2: total+1;
    if(total > 3) return index
  }
  return tasks.length
}
export const validateEmail = (email) => {
  //const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const averageColor = (imageElement) => new Promise((resolve, reject) => {
    var size = 10, canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

    canvas.width = size;
    canvas.height = size;
    var img = new Image();
    img.onload = function() {
      const imgData = context.getImageData(0, 0, size, size);
      // Get the length of image data object
      length = imgData.data.length;

      for (var i = 0; i < length; i += 4) {
          rgb.r += imgData.data[i];
          rgb.g += imgData.data[i + 1];
          rgb.b += imgData.data[i + 2];
          count++;
      }
      rgb.r = Math.floor(rgb.r / count);
      rgb.g = Math.floor(rgb.g / count);
      rgb.b = Math.floor(rgb.b / count);
      resolve(rgb)
    }
    img.onerror = error => reject(error);
    img.setAttribute('hidden', true); // works for me
    img.setAttribute('crossorigin', 'anonymous'); // put before img.src :)
    img.src = '/images/collages/Documents.svg';
})

export function fullscreen() {
  var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);
  var docElm = document.documentElement;
  if (!isInFullScreen) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
