import React from 'react'

export const EdLoading = () =>
  <div className="loadingit">
    <svg width="95px" height="61px" viewBox="0 0 95 61" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Edworking-Logo" transform="translate(-636.000000, -515.000000)">
          <g id="0" transform="translate(583.000000, 445.000000)">
            <g id="Group-4-Copy" transform="translate(100.000000, 100.500000) rotate(-55.000000) translate(-100.000000, -100.500000) translate(54.000000, 59.000000)">
              <path d="M45.9142136,0.5 C54.7507696,0.5 61.9142136,7.663444 61.9142136,16.5 L61.9142136,41.5 L61.9142136,41.5 L29.9142136,41.5 L29.9142136,16.5 C29.9142136,7.663444 37.0776576,0.5 45.9142136,0.5 Z" id="Rectangle" fill="#FCB504"></path>
              <path d="M29.9393398,41.6525433 L61.9393398,41.6525433 L61.9393398,66.6525433 C61.9393398,75.4890993 54.7758958,82.6525433 45.9393398,82.6525433 C37.1027838,82.6525433 29.9393398,75.4890993 29.9393398,66.6525433 L29.9393398,41.6525433 L29.9393398,41.6525433 Z" id="Rectangle-Copy-13" fill="#0AC9EF"></path>
              <path d="M30.8552121,-5.14727995 L31.5673858,-5.160753 C40.4422624,-5.32864985 47.7728786,1.72975184 47.9407755,10.6046284 C47.9426923,10.7059518 47.9436508,10.807291 47.9436508,10.9086325 L47.9436508,55.5294373 L47.9436508,55.5294373 C31.7207794,36.1166368 22.0024825,24.3623923 18.7887604,20.2667038 C18.7887604,20.2667038 18.7887604,20.2667038 18.7887604,20.2667038 C13.4308778,13.4384167 14.6228763,3.55957424 21.4511634,-1.7983083 C24.1385686,-3.90700719 27.4398669,-5.08266532 30.8552121,-5.14727995 Z" id="Rectangle-Copy-15" fill="#05D6A2" transform="translate(31.443651, 25.029437) scale(1, -1) rotate(-90.000000) translate(-31.443651, -25.029437) "></path>
              <path d="M59.8326279,28.2818074 L60.9105657,28.2719142 C70.1468778,28.1871442 77.7031079,35.6059349 77.7878779,44.8422471 C77.7883474,44.893409 77.7885822,44.9445727 77.7885822,44.9957368 L77.7885822,89.1170094 L77.7885822,89.1170094 C60.8172659,69.497669 50.7474926,57.6401546 47.5792623,53.5444662 C47.5792623,53.5444662 47.5792623,53.5444662 47.5792623,53.5444662 C42.2829109,46.6976768 43.5397892,36.8537162 50.3865785,31.5573648 C53.0932578,29.4636062 56.4107932,28.3131957 59.8326279,28.2818074 Z" id="Rectangle-Copy-16" fill="#FD3F67" transform="translate(60.788582, 58.617009) scale(-1, 1) rotate(-90.000000) translate(-60.788582, -58.617009) "></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div className="loading-bar"><div className="blue-bar" /></div>
  </div>
