export const AUTH_TOKEN = 'auth-token'

const colorsED1 = ['#00bceb', '#ffb400', '#ff3d67', '#00d6a2']
export const colorsED = ['#FB8C7210', '#34D7F810', '#D867C710', '#FBD06710', '#47A1ED10', '#4ED29710', '#D5E1E410']

const colors = ['#FB8C72', '#34D7F8', '#D867C7', '#FBD067', '#47A1ED', '#4ED297', '#D5E1E4']
export const rcolor = () => { return colors[Math.floor(Math.random() * Math.floor(7))] }
export const screenED = 1525
export const screenED2 = 1525
export const screenEDV = 1125
export const screenMeetingroom = 2000
export const color = (index) => { return colors[index] }
export const edcolor = (index) => { return colorsED1[index] }

export const categories = [
  {
    code: '00',
    title: 'Technology',
    color: '#00BEEB',
    icon: '/images/categories/it.svg',
    technologies: [
      { code: '00', title: 'Websites', new: false },
      { code: '01', title: 'Mobile Apps', new: false },
      { code: '02', title: 'Big Data', new: false },
      { code: '03', title: 'Machine Learning', new: false },
      { code: '04', title: 'Artificial Intellegence', new: false },
      { code: '05', title: 'Dev Ops', new: false },
      { code: '06', title: 'Chatbots', new: false },
      { code: '07', title: 'Cyber Security', new: true },
      { code: '08', title: 'Games', new: false },
      { code: '09', title: 'Testing', new: false },
      { code: '10', title: 'Blockchain', new: false },
      { code: '11', title: 'Augmented Reality', new: false },
      { code: '12', title: 'Virtual Reality', new: true },
      { code: '13', title: 'Hardware', new: false }
    ]
  },
  {
    code: '01',
    title: 'Creative',
    color: '#FF7130',
    icon: '/images/categories/creative.svg',
    technologies: [
      { code: '00', title: 'Web & Mobile Design', new: false },
      { code: '01', title: '2D Animation', new: true },
      { code: '02', title: '3D Animation', new: true },
      { code: '03', title: 'Art & Illustration', new: false },
      { code: '04', title: 'Audio Production', new: true },
      { code: '05', title: 'Brand Identity & Strategy', new: false },
      { code: '06', title: 'Graphics & Design', new: false },
      { code: '07', title: 'Logo Design & Branding', new: false },
      { code: '08', title: 'Motion Graphics', new: false },
      { code: '09', title: 'Photography', new: false },
      { code: '10', title: 'Video Editing', new: false },
      { code: '11', title: 'Voice Talent', new: false },
      { code: '12', title: 'Presentations', new: true },
      { code: '13', title: 'Logo Designers', new: true }
    ]
  },
  {
    code: '02',
    title: 'Writing',
    color: '#943AEB',
    icon: '/images/categories/writing.svg',
    technologies: [
      { code: '00', title: 'Write content', new: false },
      { code: '01', title: 'Social media', new: false },
      { code: '02', title: 'Translate', new: false },
      { code: '03', title: 'Resume Writing', new: false },
      { code: '04', title: 'Technical Writing', new: true },
      { code: '05', title: 'Article & Blog Posts', new: true }
    ]
  },
  {
    code: '03',
    title: 'Business',
    color: '#943AEB',
    icon: '/images/categories/business.svg',
    technologies: [
      { code: '00', title: 'Project Manager', new: false },
      { code: '01', title: 'Accounting', new: true },
      { code: '02', title: 'Financial', new: false },
      { code: '03', title: 'Human Resources', new: true },
      { code: '04', title: 'Business Plan', new: false },
      { code: '05', title: 'Presentations', new: false }
    ]
  },
  {
    code: '04',
    title: 'Sales/Marketing',
    color: '#943AEB',
    icon: '/images/categories/sales.svg',
    technologies: [
      { code: '00', title: 'Display Advertising', new: false },
      { code: '01', title: 'Email & Marketing Automation', new: true },
      { code: '02', title: 'Market & Customer Research', new: false },
      { code: '03', title: 'Telemarketing & Telesales', new: true },
      { code: '04', title: 'SEO - Search Engine Optimization', new: false },
      { code: '05', title: 'SMM - Social Media Marketing', new: false }
    ]
  },
  {
    code: '05',
    title: 'Engineering',
    color: '#943AEB',
    icon: '/images/categories/engineering.svg',
    technologies: [
      { code: '00', title: '3D Modeling & CAD', new: false },
      { code: '01', title: 'Architecture', new: true },
      { code: '02', title: 'Chemical Engineering', new: false },
      { code: '03', title: 'Civil & Structural Engineering', new: true },
      { code: '04', title: 'Statistics', new: false },
      { code: '05', title: 'Electrical Engineering', new: false },
      { code: '06', title: 'Electronic Engineerng', new: false },
      { code: '07', title: 'Mechanical Engineering', new: false },
      { code: '08', title: 'Maths', new: true },
      { code: '09', title: 'Science', new: true }
    ]
  },
  {
    code: '06',
    title: 'Learning',
    color: '#943AEB',
    icon: '/images/categories/learning.svg',
    technologies: [
      { code: '00', title: 'Technology', new: false },
      { code: '01', title: 'Creative', new: true },
      { code: '02', title: 'Writing', new: false },
      { code: '03', title: 'Business', new: true },
      { code: '04', title: 'Statistics', new: false },
      { code: '05', title: 'Sales/Marketing', new: false },
      { code: '06', title: 'Engineering', new: false }
    ]
  }
]
export const toolbarOptions = [
  [{ 'header': [1, 4, false] }],
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' },{ 'align': [] } ],       // toggled buttons
  ['code-block'],
  [{ 'color': ['#09323AA0', '#09323A', '#FB8C72', '#34D7F8', '#D867C7', '#FBD067', '#47A1ED', '#4ED297', '#D5E1E4', '#FFFFFF'] }, { 'background': ['#09323AA0', '#09323A', '#FB8C72', '#34D7F8', '#D867C7', '#FBD067', '#47A1ED', '#4ED297', '#D5E1E4', '#FFFFFF'] }],
  ['link' , 'image', 'video'],
  ['clean']       // toggled buttons
];
