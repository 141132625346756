import React, { Component } from 'react'

class Mobile extends Component {
  render() {
    return (
      <div className="onlymobile">
        <img src={'/images/basic/Pattern.png'} className="pattern" alt="Pattern edowrking" />
        <img src={'/images/basic/OnlyMobile.png'} className="desktop" alt="Desktop and mobile edowrking" />
        <div className="title">
          Mobile on its way...
        </div>
        <div className="description">
          Dear friends, the mobile version and the app is currently building. Please use your pc or laptop to use edworking.
        </div>
      </div>
    )
  }
}
export default Mobile
