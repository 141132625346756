import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'

import { createHttpLink } from 'apollo-link-http'
//import { BatchHttpLink } from "apollo-link-batch-http";
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { AUTH_TOKEN } from 'js/constants'
import { newLive } from 'js/utils'
import './styles/bundle.scss';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if (response && graphQLErrors){
    //response.errors = true;
    graphQLErrors.forEach(({ message, locations, path }) => {
      if(message === 'invalid token' || message === 'jwt expired' /*|| message.startsWith('Cannot return null')*/){
        console.log("Fuera: ", message)
        localStorage.removeItem(AUTH_TOKEN)
        window.location = window.location.origin
      }
      else if(message.startsWith('Wrong Project')) window.location = window.location.origin
      else if(!message.startsWith('Cannot return null')) newLive('/images/logos/Logo.svg', path,  message)
    })
  }
  else console.log("respose2", response)
  if (networkError) console.log(`[Network error]: ${networkError}`);
})
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HTTP_SERVER,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_SERVER,//'ws://10.0.1.5:3333/',//'wss://ivysaur.edworking.com/',//'ws://localhost:3333/',
    connectionParams: async () => {
      const token = localStorage.getItem(AUTH_TOKEN)
      return {
        authentication: token ? `Bearer ${token}` : ''
      };
    }
  }),
);

const finalLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink)
)

const link = ApolloLink.from([
  errorLink,
  finalLink,
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  shouldBatch: true,
})
ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
serviceWorker.register();
