import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { EdLoading } from 'js/empty'
import Mobile from './Mobile'
const Meetup = lazy(() => import('./Meetup/'));
const Dashboard = lazy(() => import('./Dashboard/'));

const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const mobile = ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || /android/i.test(userAgent) || (window.screen.width < 800))
const safari = /^((?!chrome|android).)*safari/i.test(userAgent);

function App() {
  return (
    <div style={{ overflow: 'hidden' }}>
      <Suspense fallback={<EdLoading />}>
        <Switch>
          <Route exact path={'/'} render={props => (
            <Dashboard {...props} />
          )} />
          <Route exact path={'/meetup/:meetup'} render={props => (
            //mobile ? <Mobile /> : <Meetup {...props} />
            <Meetup {...props} />
          )} />
        </Switch>
      </Suspense>
      <div id="livenoti" className="livenoti"></div>
    </div>
  )
}

export default App;
